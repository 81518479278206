import React, { useContext, useEffect, useRef, useState } from "react";
import { Offline, Online } from "react-detect-offline";
import {
  ConsolutationRequests,
  initChat,
  listOneToOneConversations,
  loadMessages,
  MAX_INACTIVITY_SEC,
  sendMessage,
  sendMessageTyping,
  prepareSingleMessage,
  listPreviousConversation,
  JSONMessage,
  BotMessage,
  notifyChat,
  messageComponent
} from "./ChatUtls";
import { ChatWindowStateContext, ChatWindowStateSetterContext } from "./ChatWindowContext";
import Back from "@material-ui/icons/ArrowBack";
import SendIcon from "@material-ui/icons/Send";
import Moment from "moment";
import SingleMessage from "./SingleMessage";
import MetaMessage from "./MetaMessage";
import Snackbar from "../../../../../common/SnackBarMessage";
import ChatTimer from "./ChatTimer";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import AstrologerCallDialog from "../AstrologerCallDialog";
import axios from "../../../../../common/interceptor";
import BrowserUtils from "../UAScripts";
import { MIN_BALANCE_MINS } from "../../../../../app.config";
import FA_localStorage from "../../../../../common/util/FA_localStorage";
import RechargeLinkRedirect, { useRechargeRedirect } from "../RechargeLinkRedirect";
import { Avatar, Button, CircularProgress, IconButton } from "@material-ui/core";
import moengage from "../../../../../common/moengage";
import AstrologerUtils from "../AstrologerUtils";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import clsx from "clsx";
import InChatAvatar from "./InChatAvatar";
import InstantPaymentPopup, { InstantPopupMessage } from "../Modal/InstantPaymentPopup";
import T2AEventTrack from "../../../../../common/T2AEventTrack";
import FreeConsultWindow from "../FreeConsultation/FreeConsultWindow";
import AstrologerWaiting from "../AstrologerWaiting";
import AstrologerRecommendation from "./AstrologerRecommendation";
import Marquee from "../Marquee";
import AstrologerAvatar from "../AstrologerAvatar";
import AlertConfirm from "../Modal/AlertConfirm";
import ChatQuestion from "../ChatQuestion";
import { ReactComponent as QuestionIcon } from "../../../../../common/question.svg";
import { Close } from "@material-ui/icons";
import FeatureSwitchConfig from "../../../../../feature.switch.config";

function Chat({
  chatID,
  sender,
  chatClient,
  isOtherUserTyping,
  setMessages,
  messages,
  closeConversation,
  activeChatStatus,
  receiver,
  setOfflineMessages,
  offlineMessages,
  setInitiatedAt,
  initiatedAt,
  enableInactivityTimer,
  setLoading,
  setActiveChatStatus,
  setCurrentServerTime,
  currentServerTime,
  astrologerGreeting,
  chatEndSummary,
  randomEndMessage,
  freeMessages = [],
  freeMessageStatus = '',
  freeMessageEvent,
}) {
  const ChatWindowData = useContext(ChatWindowStateContext);
  const [chatMessage, setChatMessage] = useState("");
  const messagesEndRef = useRef(null);
  const [astrolgerInfo, setAstrologerInfo] = useState(false);
  const [isChatAccepted, setIsChatAccepted] = useState(false);
  const [isChatPendingChat, setIsChatPendingChat] = useState(true);
  const [activeConversation, setActiveConversation] = useState({});
  const [consultation, setConsultation] = useState({});
  const [userDetails, setUserDetails] = useState(null);
  const [textMessage, setTextMessage] = useState("");
  const [elapsedTime, setElapsedTime] = useState(null);
  const [elapsedInactivity, setElapsedInactivity] = useState(0);
  const [disableEndChat, setDisableEndChat] = useState(false);
  const messageInput = useRef(null);
  const [consultationPage, setConsultationPage] = useState(1);
  const setChatWindowData = useContext(ChatWindowStateSetterContext);
  const [doNotScroll, setDoNotScroll] = useState(false);
  const [showLoadPrevConvBtn, setShowLoadPrevConvBtn] = useState(true);
  const [showPendingConsultationBox, setShowPendingConsultationBox] = useState(true);
  const [showNewConsultationBox, setShowNewConsultationBox] = useState(true);
  const [snackBarState, setSnackBarState] = useState({});
  const [showCallAstrologerPopup, setShowCallAstrologerPopup] = useState(false);
  const [showCallAstrologerPopupOpen, setShowCallAstrologerPopupOpen] = useState(false);
  const [astrologerFullProfile, setAstrologerFullProfile] = useState(false);
  const callDlgDelaySeconds = 2 * 60;
  const [popupTimerID, setPopupTimerID] = useState(0);
  const [userWalletBalance, setUserWalletBalance] = useState(-1);
  const [freeMinutes, setFreeMinutes] = useState(0)
  let popupTimer = null;
  const wallet_balance = parseInt(localStorage.getItem('walletBalance') || '0')
  const [endChatData, setEndChatData] = useState({});
  const rechargeRedirect = useRechargeRedirect()
  const [initiatedDuration, setInitiatedDuration] = useState({});
  const [screenType, setScreenType] = useState('');
  const [eventChatData, setEventChatData] = useState({})
  const [instantPaymentPopupOpen, setInstantPaymentPopupOpen] = useState(false)
  const [instantPaymentPopupShowFAB, setInstantPaymentPopupShowFAB] = useState(false)
  const [excludeAstrologers, setExcludeAstrologers] = useState([])
  const [showAstrologerWaiting, setShowAstrologerWaiting] = useState(false)
  const [reconnecting, setReconnecting] = useState(false)
  const [endChatAlert, setEndChatAlert] = useState(false)
  const [endRequestAlert, setEndRequestAlert] = useState(false)
  const [chatQuestion, setChatQuestion] = useState({
    open: false,
    excludeQuestions: [],
    questions: [],
  })
  const [enableChatQuestionFeature, setEnableChatQuestionFeature] = useState(false)
  /*
    isNewRechargeFlow is for COT_250:
    1. Recharge btn hide for free chat upto 4 min
    2. Show recharge btn after connected for paid chat.
    3. Removing 2 minute low balance message.
  */
  const isNewRechargeFlow = true; // Temporarily disabled.
  const [showRechargeButton, setShowRechargeButton] = useState(!isNewRechargeFlow)
  const [timerInfo, setTimerInfo] = useState({})
  const continueChatButtonRef = useRef(null)
  const [freeConsultationFlow, setFreeConsultationFlow] = useState({
    astrologer: freeMessageEvent.astrologer,
  })

  const MessageType = {
    MINE: 1,
    OTHER: 2,
  };

  const scrollToBottom = () => {
    if (doNotScroll === false) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      setDoNotScroll(false);
    }
  };

  const msgStatusDisplay = {
    sent: "&#10003;",
    delivered: "&#10003; &#10003;",
    read: "&#10003; &#10003;",
  };

  useEffect(() => {
    //console.log("conv:ChatWindowData", ChatWindowData);
    if (ChatWindowData.freeMinutes) {
      setFreeMinutes(ChatWindowData.freeMinutes)
    }
  }, [ChatWindowData.time]);

  const updateConversation = async (includeMessages = true) => {
    // console.log("update fn call");
    setLoading(true);
    const msgs = await listOneToOneConversations(chatID, includeMessages);
    console.log({ updateConversation: msgs })
    if (msgs.messages.length > 0) {
      setUserDetails(msgs.user);
      if (!astrolgerInfo || (astrolgerInfo?.id && parseInt(astrolgerInfo.id) === parseInt(msgs.astrologer.id))) {
        setAstrologerInfo(msgs.astrologer);
        setInitiatedAt(msgs.conversation.initiated_utc);
        setInitiatedDuration(msgs.conversation.initiated_duration);
        setActiveChatStatus(msgs.conversation.status);
      }
      setCurrentServerTime(msgs.conversation.current_time_utc);
      window.chatSessionRefreshTime = null;
    }
    // console.log("mans-msh", msgs);
    if (msgs.conversation) {
      setConsultation({
        id: msgs.conversation.id,
        status: msgs.conversation.status,
      });
    }
    setActiveConversation(msgs.conversation);
    setLoading(false);
    return msgs;
  };

  useEffect(() => {
    // console.log("consultation-update", consultation);
  }, [consultation]);

  useEffect(() => {
    setDisableEndChat(!(activeChatStatus === "initiated" || activeChatStatus === "accepted"));
    setShowNewConsultationBox(activeChatStatus === "completed" || "cancelled");
    setShowPendingConsultationBox(activeChatStatus === "pending");
    let chatStatusNotification;

    if (activeChatStatus === 'initiated' || activeChatStatus === 'failed' || activeChatStatus === 'cancelled' || activeChatStatus === 'rejected') {
      endChatTracking({ status: activeChatStatus })
    } else if (activeChatStatus === 'end' || activeChatStatus === 'missed') {
      endChatTracking({ status: 'Missed' })
    } else if (activeChatStatus === 'accepted') {
      // BrowserUtils.setFreeConsultationFlowEnded(true)
      // Reset chat_excluded_questions
      localStorage.setItem('chat_excluded_questions', JSON.stringify([]))
      afterAccepted()
      endChatTracking({ status: activeChatStatus })
    }
    if (activeChatStatus === "pending") {
      setShowAstrologerWaiting(true)
      fetchChatQuestions()
    } else {
      setShowAstrologerWaiting(false)
    }
    if (BrowserUtils.isNewUI() && (activeChatStatus === 'auto-end' || activeChatStatus === 'rejected')) {
      // setMessageComponent(statuses => { return { ...statuses, AstrologerRecommendation: true } })
      setMessages(msgs => {
        return [
          ...msgs,
          messageComponent.insert({
            name: 'AstrologerRecommendation',
            astrologerID: astrologerFullProfile.id
          })
        ]
      })
      setExcludeAstrologers(ids => [...ids, astrologerFullProfile.id])
    }

    // Fix: reconnecting glitch from AstrologerRecommendation
    if (activeChatStatus === 'failed' && reconnecting === true) {
      setTimeout(() => setReconnecting(false), 1000)
    }

  }, [activeChatStatus]);

  useEffect(() => {
    if (enableInactivityTimer) {
      startInactivityTimer();
    }
  }, [enableInactivityTimer]);

  const fetchMessages = async (initially) => {
    console.log("chatwindow-init", chatClient, chatID, activeChatStatus);

    if (chatClient) {
      setLoading(true);
      const convData = await updateConversation(false);
      if (initially) {
        // const msgs = await loadMessages(chatClient, chatID);
        const msgs = messages.length == 0 ? (convData?.messages?.length > 0 ? convData.messages : await loadMessages(chatClient, chatID)) : [];
        console.log("chatOnLoad", msgs);
        if (messages.length <= msgs.length) {
          setMessages(msgs);
        }
        setLoading(false);
      }
    } else if (BrowserUtils.isFreeConsultationFlow()) {
      setLoading(true);
      await updateConversation(false);
      setLoading(false);
    }
  }

  // Fetch messages initially
  useEffect(() => {
    console.log("chat-win-conactive-stats", activeChatStatus);
    fetchMessages(true)
    rechargeRedirect.delete()
    fetchChatQuestions()
  }, []);

  const fetchChatQuestions = () => {
    if (!enableChatQuestionFeature) {
      return
    }

    if (chatQuestion.questions.length) {
      return
    }

    // API reference: https://docs.google.com/document/d/1oWy3FRgpbzgC3xFhlfLwdePA-A5t56eUHbqRCDekkRw/edit?pli=1#heading=h.dsgq8n5unl93
    axios()
      .get("/user-questions/list", {
        params: {
          limit: 10,
          orderBy: "count",
          order: "desc"
        }
      })
      .then((res) => {
        if (res.data.success && res.data.data) {
          setChatQuestion(question => {
            return { ...question, questions: res.data.data }
          })
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Fetch messages on every pending status with updated chatID
  useEffect(() => {
    if (activeChatStatus === "pending") {
      fetchMessages(false)
    }
  }, [activeChatStatus, chatID]);

  // useEffect(() => {
  //   if (activeChatStatus === "pending" && freeMessageStatus === 'reconnect') {
  //     fetchMessages(false)
  //   }
  // }, [freeMessageStatus]);

  const getConsultIdByChatId = (chatID) => {
    const consltId = localStorage.getItem('cnslt_id_' + chatID) || ''
    return consltId;
  }

  useEffect(() => {
    if (chatID && BrowserUtils.isNewUI()) {
      const consultData = getChatConsultData()
      if (consultData.chatID !== chatID) {
        fetchChatConsultData(chatID)
      }
    }
  }, [chatID]);
  useEffect(() => {
    // console.log("offline-Message", offlineMessages);
  }, [offlineMessages]);
  // useEffect(() => {
  //   clearInterval(interval)
  //   interval = setInterval(() => {
  //     ShowCallRequestPopup()
  //   }, 1000);
  // }, []);

  // useEffect(() => {
  //   ShowAstrologerCallSuggestPopup()
  // }, []);

  useEffect(() => {
    if (showPendingConsultationBox === true && BrowserUtils.isNewUI()) {
      if (astrolgerInfo?.profile_url) {
        ShowAstrologerCallSuggestPopup()
      } else {
        console.error('No profile_url found for astrolgerInfo:', astrolgerInfo)
      }
    }
  }, [astrolgerInfo, showPendingConsultationBox]);

  useEffect(() => {

    if (BrowserUtils.isNewUI()) {
      let reqData = {};
      if (screenType == 'request_chat') {
        reqData = {
          name: 'Screen View Action',
          type: 'chat'
        }
      } else if (screenType == 'chat_cancel') {
        reqData = {
          name: 'Button Action',
          type: 'chat',
          button: 'Cancel Request'
        }
      } else if (screenType == 'call_cancel') {
        reqData = {
          name: 'Button Action',
          type: 'call',
          button: 'Cancel Request'
        }
      } else if (screenType == 'request_call') {
        reqData = {
          name: 'Screen View Action',
          type: 'call'
        }
      } else if (screenType == 'chat_continue') {
        reqData = {
          name: 'Button Action',
          type: 'chat',
          button: 'OK'
        }
      }
      if (reqData) {
        sendNewChatRequest(reqData)
      }
    }

  }, [screenType]);


  const afterAccepted = async () => {
    handleDlgClose()
  }

  const sendBirthDetails = async () => {
    if (localStorage.getItem('dob') && localStorage.getItem('tob') && localStorage.getItem('pob')) {
      const message = `Greetings! Following are my birth details
      DOB: ${localStorage.getItem('dob')}
      TOB: ${localStorage.getItem('tob')}
      POB: ${localStorage.getItem('pob')}`;
      const preparedSingleMessage = prepareSingleMessage(message, `${sender}`, chatID);
      setMessages([...messages, preparedSingleMessage]);
      const sentMessage = await sendMessage(chatClient, chatID, message);
      // console.log(sentMessage)
    }
  }

  useEffect(() => {
    // console.log('ch:astrologerGreeting', astrologerGreeting, activeChatStatus)
    if (activeChatStatus === 'accepted') {
      setTimeout(sendBirthDetails, 1500)
    }
  }, [astrologerGreeting.timestamp])

  const freeChatMinutes = (profile) => {
    const sKey = 'fcm_' + chatID
    let free_chat_minutes = parseInt(localStorage.getItem(sKey) || '0')
    if (profile && profile?.free_chat_minutes) {
      free_chat_minutes = profile.free_chat_minutes
      localStorage.setItem(sKey, free_chat_minutes)
    }
    return free_chat_minutes
  }

  const clearChatConsultData = () => {
    const consultInfo = localStorage.getItem('lastConsultData');
    if (consultInfo) {
      localStorage.removeItem('lastConsultData');
    }
  }

  const getChatConsultData = () => {
    let lastConsultInfo = {
      chatID: '',
      consult_id: 0,
      free_minute: 0,
      astrologer_id: 0
    };
    try {
      const consultInfo = localStorage.getItem('lastConsultData');
      if (consultInfo) {
        lastConsultInfo = JSON.parse(consultInfo);
      }
    } catch (error) {
      console.error('Error parsing JSON from localStorage:', error);
    }
    return lastConsultInfo;
  }

  const fetchChatConsultData = (chatID) => {
    axios()
      .post("/user/chat/chat-consult-info", {
        chatID
      })
      .then((res) => {
        if (res.data.success) {
          setFreeMinutes(res.data.free_consult_min)
          localStorage.setItem('lastConsultData', JSON.stringify({
            chatID: chatID,
            consult_id: res.data.consult_id,
            free_minute: res.data.free_consult_min,
            astrologer_id: res.data.astrologer_id
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const sendConsultInfotoMoengage = (data) => {
    if (BrowserUtils.isNewUI() && (activeChatStatus === 'initiated')) {
      let userAttribute = {
        'Last consulted astrologer ID': data.id,
        'Last consulted astrologer name': data.name
      }
      const consultData = getChatConsultData()
      console.log(data, chatID, JSON.stringify(consultData))
      if (consultData.chatID === chatID) {
        userAttribute['Last consulted Id'] = consultData.consult_id;
        if (parseInt(consultData.free_minute) === 0) {
          userAttribute['Last consulted paid chat astrologer ID'] = data.id;
          userAttribute['Last consulted paid chat astrologer ID'] = data.name;
          userAttribute['Last consulted type'] = 'Paid chat';
        }
      }
      setEventChatData({
        event: {},
        extra: userAttribute,
        END_TIME: Date.now()
      })
      T2AEventTrack({}, userAttribute);
    }
  }

  const endChatTracking = (summary) => {
    //console.log(astrologerFullProfile)
    //const freeSeconds = (freeChatMinutes(astrologerFullProfile) * 60)
    //const freeSeconds = parseInt(freeMinutes) * 60
    if (BrowserUtils.isNewUI()) {
      let freeSeconds = 0;
      let consultationId = summary?.consult_id || 0
      const consultData = getChatConsultData()
      if (consultData.chatID === chatID) {
        freeSeconds = parseInt(consultData.free_minute) * 60
        if (parseInt(consultationId) === 0) {
          consultationId = consultData.consult_id
        }
      } else if (summary.status !== 'requested') {
        console.log('loaded time incorrect', JSON.stringify(summary))
        return;
      }


      let totalMins = -1
      let points = 0
      //console.log('free second', freeSeconds)

      if (summary) {
        if (summary?.body?.text?.action === 'chat-end-summary') {
          let min = summary.body.text.text.split(' min')
          min = min[min.length > 2 ? 2 : 0].split(' ').at(-1)
          totalMins = parseInt(min) || 0

          let pts = summary.body.text.text.split(' pts')
          pts = pts[0].split(' ').at(-1)
          points = parseInt(pts) || 0
          summary.status = 'completed';
          localStorage.setItem('last_consulted_astrologer', astrologerFullProfile.id)
          clearChatConsultData();
        } else if (summary.status) {
          totalMins = 0;
        }
        console.log(summary)
      }

      let c_type = 'paid';
      let consltType = 'Paid';

      if (freeSeconds > 0) {
        c_type = 'free';
        if (freeSeconds < (totalMins * 60)) {
          consltType = 'Free paid'
        } else {
          consltType = 'Free'
        }
      }

      // Get user id
      let userID = sender ? sender.split('-').at(-1) : 0
      if (!userID && localStorage.getItem('userProfileId')) {
        userID = localStorage.getItem('userProfileId')
      }

      const data = {
        event: {
          name: 'Consultation',
          attributes: {
            'Screen Name': 'Chat Screen',
            'Consultation Name': 'Chat',
            'Consultation Type': consltType,
            'Consultation Status': summary.status,
            'Consultation Duration': totalMins,
            'Consultation amount': `${points} pts`,
            'User ID': userID,
            'User Name': localStorage.getItem('name'),
            'Astrologer ID': astrologerFullProfile.id || astrolgerInfo?.id,
            'Astrologer Name': astrologerFullProfile.name || astrolgerInfo?.name,
            'Product': 'COT',
            'Consultation ID': parseInt(consultationId) || 0
          }
        },
        extra: {
          user: {
            'User ID': userID,
            'User Name': localStorage.getItem('name'),
            [`Last consulted ${c_type} chat astrologer ID`]: astrologerFullProfile.id,
            [`Last consulted ${c_type} chat astrologer name`]: astrologerFullProfile.name,
            'Current wallet balance': localStorage.getItem('walletBalance'),
            'Last chat attempt status': summary.status,
            'Last chat attempt time stamp': Date.now(),
          }
        },
        END_TIME: summary?.body?.text?.time,
      }

      // console.log('ch:endChatTracking', {
      //   totalMins,
      //   data,
      // })


      if (totalMins > 0) {
        data.extra.user['Last consulted astrologer ID'] = astrologerFullProfile.id;
        data.extra.user['Last consulted astrologer name'] = astrologerFullProfile.name;
        data.extra.user['Last consulted Id'] = consultationId;
        data.extra.user['Last consulted type'] = `${consltType} chat`;
        setEndChatData(data)
      } else if (totalMins == 0 && summary.status) {
        data.END_TIME = Date.now()
        setEventChatData(data)
      }
      console.log('moengage events ', data)
    }
  }

  useEffect(() => {
    //console.log('chatendsummary event', chatEndSummary)
    if (chatEndSummary?.timestamp) {
      setTimeout(() => endChatTracking(chatEndSummary), 1500)
    }
  }, [chatEndSummary.timestamp])

  useEffect(() => {
    if (endChatData.END_TIME) {

      axios()
        .post("/profile/updates")
        .then((res) => {
          if (res && res.data.success) {
            const data = endChatData
            const wallet_balance = res.data.data.wallet_balance || 0
            FA_localStorage("walletBalance", wallet_balance)
            if (res.data.data.hasOwnProperty('user_status')) {
              BrowserUtils.setCachedKey('userStatus', res.data.data.user_status);
            }
            setUserWalletBalance(wallet_balance)
            data.extra.user['Current wallet balance'] = wallet_balance
            data.extra.user['COT wallet balance'] = wallet_balance
            // console.log('ch:endChatData:moengage', data)
            if (res.data.data.hasOwnProperty('totalRecharge')) {
              data.extra.user['COT LTV'] = res.data.data.totalRecharge;
            }
            if (res.data.data.hasOwnProperty('consultCost')) {
              data.extra.user['COT consultation charges'] = res.data.data.consultCost;
            }
            T2AEventTrack(data.event, data.extra)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [endChatData.END_TIME])

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setLoading(true);
    setTextMessage(chatMessage);
    setChatMessage("");
    messageInput.current.focus();
    messageInput.current.value = null;
    setChatQuestion(question => {
      return { ...question, open: false }
    })
    // console.log("setting offline message", sender, chatID, chatMessage);
    const preparedSingleMessage = prepareSingleMessage(textMessage, `${sender}`, chatID);
    setMessages([...messages, preparedSingleMessage]);
    // setOfflineMessages([...offlineMessages, preparedSingleMessage]);

    const sentMessage = await sendMessage(chatClient, chatID, textMessage);
    if (sentMessage !== false) {
      setOfflineMessages([]);
      // setMessages([...messages, preparedSingleMessage]);
    } else {
      setOfflineMessages([]);
      window.location.reload();
    }
    // setLoading(false);
  };

  const handleMesssage = (e) => {
    setTextMessage(e.target.value.split("<br/>").join("\n"));
    setChatMessage(e.target.value);
    setChatQuestion(question => {
      return { ...question, open: e.target.value === '' }
    })
  };
  const isHTML = (str) => {
    var a = document.createElement("div");
    a.innerHTML = str;

    for (var c = a.childNodes, i = c.length; i--;) {
      if (c[i].nodeType === 1) return true;
    }
    return false;
  };
  const validateInput = (e) => {
    let msg = messageInput.current.value;

    if (msg.trim().length === 0) {
      e.preventDefault();
      return false;
    }
    if (isHTML(msg)) {
      e.preventDefault();
      return false;
    }
    return true;
  };
  useEffect(() => {
    console.log("ch:list-messages", chatID, messages);
    // console.log("scroll-to-bottom");
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    // console.log("init", chatClient);
  }, [chatClient]);

  useEffect(() => {
    if (BrowserUtils.isNewUI() && astrolgerInfo.profile_url) {
      axios()
        .post("/astrologers/" + astrolgerInfo.profile_url)
        .then((res) => {
          if (res.data.success) {
            const profile = res.data.data
            profile.free_minutes = profile.free_chat_minutes
            profile.astrologerFee = profile.price ? parseInt(profile.price.split("/")[0], 10) : 0
            const consultData = getChatConsultData()
            if (consultData.chatID === chatID) {
              profile.free_call_minutes = consultData.free_minute;
              profile.free_chat_minutes = consultData.free_minute;
              profile.free_minutes = consultData.free_minute;
            }
            setAstrologerFullProfile(profile)
            freeChatMinutes(profile)
            /*
            if (!screenType) {
              setScreenType('request_chat')
            }
            */
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [astrolgerInfo])


  const chatScreenView = (data) => {
    let consultStatus = 'requested'
    if (activeChatStatus == 'initiated' || activeChatStatus == 'accepted') {
      consultStatus = activeChatStatus
    }
    let consultationType = 'Paid'
    if (ChatWindowData?.freeMinutes || data.free_chat_minutes) {
      consultationType = 'Free'
    }
    const eData = {
      event: {
        name: 'Screen View Action',
        attributes: {
          'Screen Name': 'Chat consultation screen',
          'Consultation Type': consultationType,
          'Product': 'COT',
          'Consultation Status': consultStatus,
          'Astrologer ID': data.id,
          'Astrologer Name': data.name,
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name
        }
      },
      extra: {
        user: {
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name
        }
      },
      END_TIME: Date.now()
    }
    setEventChatData(eData)
  }

  useEffect(() => {
    if (BrowserUtils.isNewUI() && astrologerFullProfile?.id) {
      const consultInfo = getChatConsultData()
      if (consultInfo?.chatID === chatID) {
        astrologerFullProfile.free_call_minutes = consultInfo.free_minute;
        astrologerFullProfile.free_chat_minutes = consultInfo.free_minute;
        astrologerFullProfile.free_minutes = consultInfo.free_minute;
      } else if (freeMinutes) {
        astrologerFullProfile.free_call_minutes = freeMinutes;
        astrologerFullProfile.free_chat_minutes = freeMinutes;
        astrologerFullProfile.free_minutes = freeMinutes;
      }
      chatScreenView(astrologerFullProfile)
    }
  }, [astrologerFullProfile.id])

  const showError = (message) => {
    setSnackBarState({
      open: true,
      message: message,
    });
  };
  const closeError = () => {
    setSnackBarState({ open: false });
  };

  const sendNewChatRequest = (data) => {
    if (BrowserUtils.isNewUI() && astrolgerInfo) {

      let free_chat_minutes = parseInt(localStorage.getItem('fcm_' + chatID) || '0')
      /*
      if (free_chat_minutes && astrologerFullProfile) {
        if (data.type == 'call') {
          if (astrologerFullProfile?.free_call_minutes) {
            free_chat_minutes = astrologerFullProfile.free_call_minutes
          }
        } else if (astrologerFullProfile?.free_call_minutes) {
          free_chat_minutes = astrologerFullProfile.free_call_minutes
        }
      }
      */

      const consultData = getChatConsultData()
      if (consultData.chatID === chatID) {
        free_chat_minutes = parseInt(consultData.free_minute)
      }

      const payload = {
        event: {
          name: data.name,
          attributes: {
            'Screen Name': 'Requesting ' + data.type,
            'Source Screen Name': 'Chat Screen',
            'Astrologer ID': astrolgerInfo.id,
            'Astrologer Name': astrolgerInfo.name,
            'Consultation Name': (data.type == 'call') ? 'Call' : 'Chat',
            'Consultation Type': (free_chat_minutes > 0) ? 'Free' : 'Paid',
            'User ID': parseInt(`${sender}`.split('-').at(-1)) || 0,
            'User Name': localStorage.getItem('name'),
            'Product': 'COT'
          }
        },
        extra: {
          user: {
            'User ID': parseInt(`${sender}`.split('-').at(-1)) || 0,
            'User Name': localStorage.getItem('name'),
          }
        },
        END_TIME: Date.now()
      }

      if (data.button) {
        payload.event.attributes['Button Name'] = data.button;
      }
      //console.log({ 'send new event payload': payload, 'chatID': chatID, 'consultData': consultData })
      T2AEventTrack(payload.event, payload.extra)
    }
  }

  const rechargeButtonAction = (data) => {
    if (BrowserUtils.isNewUI()) {
      const eData = {
        event: {
          name: 'Buy Now Action',
          attributes: {
            'Screen Name': 'Chat Screen',
            'Button Name': data.buttonName,
            'Target Screen Name': 'User Wallet',
            'Button Location': data.location,
            'Source Screen Name': data.location,
            'Product': 'COT',
            'User ID': BrowserUtils.CurrentUserInfo().id,
            'User Name': BrowserUtils.CurrentUserInfo().name
          }
        },
        extra: {
          user: {
            'User ID': BrowserUtils.CurrentUserInfo().id,
            'User Name': BrowserUtils.CurrentUserInfo().name
          }
        }
      }
      console.log({ rechargeButtonAction: eData });
      T2AEventTrack(eData.event, eData.extra);
    }
  }

  const newConsultation = async (e) => {
    setLoading(true);
    setShowNewConsultationBox(false);
    const chatInfo = await initChat(receiver); // @todo: get it dynamically
    if (chatInfo && chatInfo.success === true) {
      setChatWindowData({
        astrologer: receiver ? receiver : ChatWindowData.astrologer,
        time: Date.now(),
        chatID: chatInfo.chatID,
        status: chatInfo.status,
      });
      let consultId = chatInfo.receiver?.consultationId || 0;
      if (consultId) {
        notifyChat(consultId)
      }
      console.log('new initiated consult event -> ', consultId)
      setConsultationPage(consultationPage + 1);
      setScreenType('chat_continue')
      endChatTracking({ status: 'requested', consult_id: consultId })
    } else {
      // To handle the chat connect failed condition 
      if (e?.onChatConnectFailed) {
        e.onChatConnectFailed()
      } else {
        showError(chatInfo.message);
      }
      setShowNewConsultationBox(true);
    }
    //console.log(screenType)
    //console.log(activeChatStatus)
    if (activeChatStatus !== 'pending' && screenType) {
      setScreenType('chat_continue')
    }
    setLoading(false);
  };

  const cancelConsultation = async (callBack = () => { }) => {
    clearTimeout(popupTimerID)
    setLoading(true);
    setShowPendingConsultationBox(false);
    const chatInfo = await ConsolutationRequests.cancel(chatID);
    if (chatInfo && chatInfo.success === true) {
      setChatWindowData({
        astrologer: receiver ? receiver : ChatWindowData.astrologer,
        time: Date.now(),
        chatID: chatInfo.chatID,
        status: (chatInfo.status == 'failed') ? 'cancelled' : chatInfo.status,
      });
      if (screenType === '' || screenType === 'request_chat' || screenType === 'chat_continue') {
        setScreenType('chat_cancel')
      } else if (screenType == 'request_call') {
        setScreenType('call_cancel')
      }
    } else {
      showError(chatInfo.message);
      setShowPendingConsultationBox(true);
    }
    setLoading(false)

    if (typeof callBack === 'function') {
      callBack()
    }
  };

  const endConsultation = async (e) => {
    setLoading(true);
    const chatInfo = await ConsolutationRequests.forceEnd(chatID);
    if (chatInfo && chatInfo.success === true) {
      setChatWindowData({
        astrologer: receiver ? receiver : ChatWindowData.astrologer,
        time: Date.now(),
        chatID: chatInfo.chatID,
        status: chatInfo.status,
      });
    } else {
      showError(chatInfo.message);
      setShowPendingConsultationBox(true);
    }
    setLoading(false);
  };

  const handleKeyDown = (e) => {
    let msgBox = messageInput.current.value;
    if (
      (e.code === "Enter" || e.code === "NumpadEnter" || e.keyCode === 13) &&
      !e.shiftKey &&
      msgBox.trim().length !== 0
    ) {
      handleSubmit(e);
      return true;
    }
    const msg = "typing...";
    sendMessageTyping(chatClient, chatID, msg);
  };
  const loadPrevMessage = async (chatID) => {
    setLoading(true);
    setDoNotScroll(true);
    setConsultationPage(consultationPage + 1);
    const prevousMessages = await listPreviousConversation({ chatID, page: consultationPage });
    if (prevousMessages.success === true) {
      const breakerMessage = prepareSingleMessage("hr", `breaker`, chatID);
      // console.log("updated", breakerMessage);
      setMessages([...prevousMessages.messages, breakerMessage, ...messages]);
    } else {
      setConsultationPage(consultationPage - 1);
      setShowLoadPrevConvBtn(false);
      showError(prevousMessages.message);
    }
    setLoading(false);
  };

  const startInactivityTimer = () => {
    const countUpTimer = () => {
      setTimeout(() => {
        if (window.chatSessionRefreshTime) {
          const diffMilli = Date.now() - window.chatSessionRefreshTime;
          const s = Math.floor(diffMilli / 1000);
          if (s <= 120) {
            setElapsedInactivity(s);
          }
        }
        countUpTimer();
      }, 1000);
    };
    if (!elapsedInactivity) {
      countUpTimer();
    }
  };

  useEffect(() => {
    if (eventChatData?.END_TIME) {
      console.log('eventChatData', eventChatData)
      T2AEventTrack(eventChatData.event, eventChatData.extra)
    }
    console.log('send event datas', eventChatData)
    return () => {
      //console.log('clear all event data')
    }
  }, [eventChatData.END_TIME])

  const RechargeComponent = ({ children }) => {
    console.log('RechargeComponent')
    const minBalance = astrologerFullProfile.astrologerFee * MIN_BALANCE_MINS

    useEffect(() => {
      if (userWalletBalance === -1) {
        axios()
          .post("/profile/updates")
          .then((res) => {
            if (res && res.data.success) {
              FA_localStorage("walletBalance", res.data.data.wallet_balance || 0)
              setUserWalletBalance(res.data.data.wallet_balance || 0)
              if (BrowserUtils.isNewUI() && res.data.data.hasOwnProperty('user_status')) {
                BrowserUtils.setCachedKey('userStatus', res.data.data.user_status);
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }, [astrologerFullProfile])

    const ActionButton = () => {
      console.log('RechargeComponent:Recharge and continue', minBalance > userWalletBalance)
      if (minBalance > userWalletBalance) {
        return <RechargeLinkRedirect
          refData={{
            refID: 'chat/request-consultation/insufficient-balance',
            astrologerID: astrologerFullProfile.id,
            astrologerName: astrolgerInfo.name,
            astrologerProfileURL: astrolgerInfo.profile_url,
            astrologerCharge: astrologerFullProfile?.perMinutePrice || 0,
            type: 'chat',
            maxAmount: 1000,
            highlight: 499,
            location: 'Chat Bottom',
          }}
          onClick={(e) => {
            moengage('Recharge Click Chat Bottom')
            rechargeButtonAction({
              buttonName: 'Continue Chat',
              location: 'Chat Bottom'
            });
            e.goToWallet()
          }}
        >
          <button>Continue Chat</button>
        </RechargeLinkRedirect>
      }

      return children
    }

    return userWalletBalance === -1 ? <CircularProgress /> : <ActionButton />
  }

  const ContinueChatButton = () => {
    return (
      <button
        onClick={() => {
          newConsultation({
            onChatConnectFailed: () => {
              continueChatButtonRef.current?.click()
            }
          })
        }}
      >Continue Chat</button>
    )
  }

  const NewConsultationBox = () => {
    //console.log('NewConsultationBox')
    return (
      <>
        <div>
          {BrowserUtils.isNewUI() ?
            // <RechargeComponent
            //   astrologerFullProfile={astrologerFullProfile}
            // >
            //   <button onClick={newConsultation}>Continue Chat</button>
            // </RechargeComponent>
            <ContinueChatButton />
            :
            <button onClick={newConsultation}>Request consultation</button>}
        </div>
      </>
    );
  };

  const PendingConsultationBox = () => {

    // ShowAstrologerCallSuggestPopup()

    return (
      <>
        <div className="PendingConsultationBox" style={{ padding: 8, backgroundColor: "#fff" }}>
          <span>Please wait for the astrologer to accept the chat consultation.</span>
          <button
            className="PendingConsultationBox-btn"
            style={{ backgroundColor: "#f00", color: "#fff" }}
            onClick={e => setEndRequestAlert(true)}
          >
            Cancel request
          </button>
        </div>
      </>
    );
  };

  const ShowAstrologerCallSuggestPopup = () => {
    BrowserUtils.devConsole('ShowAstrologerCallSuggestPopup:astrolgerInfo', astrolgerInfo)
    clearTimeout(popupTimerID)
    popupTimer = setTimeout(() => {
      axios()
        .post("/astrologers/" + astrolgerInfo.profile_url)
        .then((res) => {
          if (res.data.success) {
            const profile = res.data.data
            profile.free_minutes = profile.free_call_minutes
            const consultData = getChatConsultData()
            if (consultData.chatID === chatID) {
              profile.free_call_minutes = consultData.free_minute;
              profile.free_chat_minutes = consultData.free_minute;
              profile.free_minutes = consultData.free_minute;
            }
            setAstrologerFullProfile(profile)
            BrowserUtils.devConsole('ShowAstrologerCallSuggestPopup:setAstrologerFullProfile', profile)

            if (isPending() && profile.chargesAudio) {
              setShowCallAstrologerPopup(true);
              setShowCallAstrologerPopupOpen(true);
              if (screenType) {
                setScreenType('request_call')
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });


    }, callDlgDelaySeconds * 1000)
    setPopupTimerID(popupTimer)
  }

  const isPending = () => {
    BrowserUtils.devConsole('isPending: ', (activeChatStatus === "pending" && showPendingConsultationBox === true), ', activeChatStatus:', activeChatStatus, ', showPendingConsultationBox:', showPendingConsultationBox)
    return (activeChatStatus === "pending" && showPendingConsultationBox === true)
  }

  const handleDlgClose = () => {
    isPending()
    BrowserUtils.devConsole('popupTimerID:', popupTimerID)
    clearTimeout(popupTimerID)
    setShowCallAstrologerPopup(false);
    setShowCallAstrologerPopupOpen(false);
  }

  const ShowCallRequestPopup = () => {
    if (activeChatStatus === 'pending' && !showCallAstrologerPopup) {
      let chat = null;
      messages.forEach(m => {
        const action = m?.body?.text?.action
        if (action === 'chat-pending') {
          chat = m
        }
        if (action === 'chat-end' || action === 'chat-cancel') {
          chat = null
        }

        // console.log('ShowCallRequestPopup:m', m)
      });

      // console.log('ShowCallRequestPopup:messages', messages)
      // console.log('ShowCallRequestPopup:activeChatStatus', activeChatStatus)
      // console.log('ShowCallRequestPopup:chat', chat)
      if (chat) {
        const duration = Moment.duration(Moment(new Date()).diff(Moment(chat.created)));
        const seconds = duration.asSeconds();
        // console.log('ShowCallRequestPopup:seconds', seconds)

        if (seconds > 10) {
          setShowCallAstrologerPopup(true)
        } else {
          setShowCallAstrologerPopup(false)
        }
      }
    } else {
      setShowCallAstrologerPopup(false)
    }
  }

  const reloadApp = () => {
    window.location.href = "/";
  };

  const OfflineConsultationBox = () => {
    return (
      <>
        <div style={{ padding: 8, backgroundColor: "#fff" }}>
          <span>
            You are offline. It seems there is a problem with your connection. Please check you network connection.
          </span>
          <button style={{ backgroundColor: "#f00", color: "#fff" }} onClick={reloadApp}>
            Restart App
          </button>
        </div>
      </>
    );
  };

  const openInstantPaymentPopup = () => {

    if (FeatureSwitchConfig.instant_popup.enabled_on_chat_window) {
      if (
        // Show FAB icon if showing the wallet recharge screen
        window.location.hash.includes(`back-button--wallet-recharge`)

        // Check the the instant popup FAB icon enabled on the chat window
        || !FeatureSwitchConfig.instant_popup.enabled_automatic_on_chat_window
      ) {
        setInstantPaymentPopupShowFAB(true)
      } else {
        setInstantPaymentPopupOpen(true)
      }
    }

  }

  useEffect(() => {
    if (randomEndMessage !== false) {
      setTimeout(() => {
        // Open Instant payment popup for free user
        if (freeChatMinutes(astrologerFullProfile) > 0) {
          openInstantPaymentPopup()
        } else {
          // Check user eligible balance for paid user
          axios()
            .post("/profile/updates")
            .then((res) => {
              if (res && res.data.success) {
                const wallet_balance = res.data.data.wallet_balance || 0
                const minBalance = astrologerFullProfile.astrologerFee * MIN_BALANCE_MINS
                if (minBalance > wallet_balance) {
                  openInstantPaymentPopup()
                }
                if (BrowserUtils.isNewUI() && res.data.data.hasOwnProperty('user_status')) {
                  BrowserUtils.setCachedKey('userStatus', res.data.data.user_status);
                }
              }
            })
            .catch((err) => {
              console.log(err)
            })
        }
      }, 5000)
    }
  }, [randomEndMessage])

  const sendJSONMessage = (message) => {
    const defaultMessage = {
      text: `Please be aware that the user's instant payment popup is now open, indicating they may be in the process of recharging. We kindly request your patience and ask that you wait for 3 minutes to allow them to complete their transaction.`,
      bot_type: 'instant-popup-open',
      // visibility: 'astrologer',
    }
    const jsonMessage = JSONMessage.send({
      sender,
      chatID,
      chatClient,
      message: {
        ...defaultMessage, ...message
      },
      type: 'bot',
    });
    setMessages([...messages, jsonMessage])
  }

  useEffect(() => {
    if (astrologerFullProfile?.id) {
      let chatStatusNotification;
      // For free chat users
      if (freeChatMinutes(astrologerFullProfile) > 0) {
        if (isNewRechargeFlow && timerInfo?.lastMinute) {
          setShowRechargeButton(true)
        }
      } else if (activeChatStatus !== 'pending') {
        setShowRechargeButton(true)
      }

      //console.log(chatID, activeChatStatus, JSON.stringify(astrologerFullProfile));

      if (activeChatStatus === 'initiated') {
        chatStatusNotification = setTimeout(() => {
          sendConsultInfotoMoengage(astrologerFullProfile);
        }, 2 * 60 * 1000);
      }

      console.log('activeChatStatus:' + activeChatStatus)

      return () => clearTimeout(chatStatusNotification);
    }
  }, [astrologerFullProfile, timerInfo, activeChatStatus])

  return (
    <div className={clsx(
      'chat-wrap',
      activeChatStatus === 'free-consultation' ? 'free-consult' : '',
      'chat-question--enabled',
      chatQuestion.open && enableChatQuestionFeature ? 'chat-question--open' : '',
      BrowserUtils.isNewUI() ? 'new-chat-ui' : ''
    )}>
      <Snackbar
        vertical="top"
        horizontal="right"
        message={snackBarState.message}
        closeError={closeError}
        open={snackBarState.open}></Snackbar>
      <div className={clsx("chat-window-header", reconnecting ? 'reconnecting' : '')}>
        <span className="back" onClick={closeConversation}>
          <Back />
        </span>
        {BrowserUtils.isNewUI() ?
          <>
            <span className="astrologer-display">
              <AstrologerAvatar
                astrologer={freeConsultationFlow.astrologer || astrologerFullProfile}
              />
              {(freeMessages?.messages && freeMessages.messages.length) ?
                <span className="name"><Marquee>FREE CONSULTATION: with {astrolgerInfo && astrolgerInfo.name}</Marquee></span> :
                <span className="name">{astrolgerInfo && astrolgerInfo.name}</span>
              }

              {(FeatureSwitchConfig.instant_popup.enabled_on_chat_window && astrolgerInfo.name) &&
                <InstantPaymentPopup {...{
                  open: instantPaymentPopupOpen,
                  astrologer: { ...astrolgerInfo, perMinutePrice: astrologerFullProfile?.perMinutePrice || 0 },
                  chatID,
                  showFAB: instantPaymentPopupShowFAB,
                  onClose: () => setInstantPaymentPopupOpen(false)
                }}
                />
              }
            </span>
          </>
          :
          <span>{astrolgerInfo && astrolgerInfo.name}</span>
        }
      </div>
      <div className={clsx("chat-action-bar", wallet_balance === 0 ? 'zero-balance' : '', reconnecting ? 'reconnecting' : '')}>
        {activeChatStatus === "initiated" ? (
          initiatedDuration ?
            <span>
              Time{wallet_balance === 0 ? '' : ' left'}: <ChatTimer
                initiatedAt={initiatedAt}
                initiatedDuration={initiatedDuration}
                currentServerTime={currentServerTime}
                updateInfo={e => setTimerInfo(e)}
              />
            </span>
            :
            <span className="chat-action-barelapsed-timer">
              {/* Time elapsed: <ChatTimer
                initiatedAt={initiatedAt}
                currentServerTime={currentServerTime}
              /> */}
            </span>

        ) : (
          <span className={clsx('chat-status', `status-${activeChatStatus}`)} style={{ opacity: 0.6 }}>
            <span className="name">Status: </span>
            <span className="value">
              {activeChatStatus === 'pending' ?
                <span className="threeDotAnimation">connecting</span> :
                activeChatStatus
              }
            </span>
          </span>
        )}
        <span className={clsx(
          'chat-inactivity-timer',
          (activeChatStatus === "initiated" && enableInactivityTimer & (elapsedInactivity > 0)) ? 'timer-running' : ''
        )}>
          {activeChatStatus === "initiated" && enableInactivityTimer & (elapsedInactivity > 0) ? (
            <CircularProgressWithLabel
              variant="determinate"
              value={elapsedInactivity / MAX_INACTIVITY_SEC / 0.01}
              label={MAX_INACTIVITY_SEC - elapsedInactivity}
            />
          ) : (
            <></>
          )}
        </span>

        {/* {(BrowserUtils.isNewUI() && astrologerFullProfile && freeChatMinutes(astrologerFullProfile) > 0) ? */}
        {(BrowserUtils.isNewUI() && wallet_balance === 0) ?
          <span className="chat-wallet-balance">
            <AccountBalanceWalletIcon fontSize="default" />
            <span>0 pts</span>
          </span>
          :
          null
        }

        {BrowserUtils.isNewUI() ?
          <>
            <span className="chat-right-btns">
              {showRechargeButton ?
                <RechargeLinkRedirect
                  refData={{
                    refID: 'chat/recharge-top-button',
                    astrologerID: astrolgerInfo.id,
                    astrologerName: astrolgerInfo.name,
                    astrologerProfileURL: astrolgerInfo.profile_url,
                    astrologerCharge: astrologerFullProfile?.perMinutePrice || 0,
                    type: 'chat',
                    maxAmount: 1000,
                    highlight: 499,
                    location: 'Chat Top'
                  }}
                  onClick={async (e) => {
                    if (activeChatStatus === 'pending') {
                      await cancelConsultation()
                    } else if (!isNewRechargeFlow) {
                      await endConsultation()
                    }
                    rechargeButtonAction({
                      buttonName: 'Recharge',
                      location: 'Chat Top'
                    });
                    e.goToWallet()
                  }}
                >
                  <Button className="btn-action btn-recharge" id="chat-recharge-button">
                    Recharge
                  </Button>
                </RechargeLinkRedirect>
                :
                <></>
              }
              <div style={{ display: 'none' }}>
                <RechargeLinkRedirect
                  refData={{
                    refID: 'chat/recharge-inchat-button',
                    astrologerID: astrolgerInfo.id,
                    astrologerName: astrolgerInfo.name,
                    astrologerProfileURL: astrolgerInfo.profile_url,
                    astrologerCharge: astrologerFullProfile?.perMinutePrice || 0,
                    type: 'chat',
                    maxAmount: 1000,
                    highlight: 499,
                    location: 'In chat'
                  }}
                  onClick={async (e) => {
                    if (activeChatStatus === 'pending') {
                      await cancelConsultation()
                    } else if (!isNewRechargeFlow) {
                      await endConsultation()
                    }
                    rechargeButtonAction({
                      buttonName: 'Recharge',
                      location: 'In chat'
                    });
                    e.goToWallet()
                  }}
                >
                  <Button className="btn-action btn-recharge" id="chat-action-bar-recharge-button"> </Button>
                </RechargeLinkRedirect>

                <RechargeLinkRedirect
                  refData={{
                    refID: 'chat/request-consultation/insufficient-balance',
                    astrologerID: astrologerFullProfile.id,
                    astrologerName: astrolgerInfo.name,
                    astrologerProfileURL: astrolgerInfo.profile_url,
                    astrologerCharge: astrologerFullProfile?.perMinutePrice || 0,
                    type: 'chat',
                    maxAmount: 1000,
                    highlight: 499,
                    location: 'Chat Bottom',
                  }}
                  onClick={(e) => {
                    moengage('Recharge Click Chat Bottom')
                    rechargeButtonAction({
                      buttonName: 'Continue Chat',
                      location: 'Chat Bottom'
                    });
                    e.goToWallet()
                  }}
                >
                  <button ref={continueChatButtonRef}>Continue Chat</button>
                </RechargeLinkRedirect>
              </div>
              {(activeChatStatus === "pending" && showPendingConsultationBox) ?
                <Button
                  className="btn-action btn-cancel-request"
                  onClick={e => setEndRequestAlert(true)}
                >
                  Cancel request
                </Button>
                :
                <Button className="btn-action btn-end" disabled={disableEndChat} onClick={e => setEndChatAlert(true)}>
                  End chat
                </Button>
              }
            </span>
          </>
          :
          <span>
            <button disabled={disableEndChat} onClick={endConsultation}>
              End consultation
            </button>
          </span>
        }
      </div>
      <div className={clsx('chat-window-body', `status-${activeChatStatus}`, reconnecting ? 'reconnecting' : '')}>
        <div className="chat-messages">
          {showLoadPrevConvBtn && (
            <div className="chat-window-previous-conversation">
              <button
                onClick={() => {
                  loadPrevMessage(chatID);
                }}>
                Load previous consultation
              </button>
            </div>
          )}
          {messages.length === 0 && <div className="chat-message-text">Loading conversations...</div>}
          {freeConsultationFlow?.astrologer ?
            <FreeConsultWindow
              freeMessages={freeMessages}
              freeMessageStatus={freeMessageStatus}
              freeMessageEvent={freeMessageEvent}
              onlyMessages={true}
            />
            : null
          }
          {messages
            .filter((item, index, self) => {
              let includeMessage = false

              // Remove duplicates
              if (index === self.findIndex(t => t.id === item.id)) {
                includeMessage = true
              }

              // Remove above 1 minute low chat message
              if (isNewRechargeFlow
                && item?.body?.text?.action && item.body.text.action === 'chat-low-balance'
                && item?.body?.text?.text && !item.body.text.text.includes('1 minute')) {
                includeMessage = false
              }

              return includeMessage
            })
            .map((msg, index) => {
              let msgStatus = "sent"
              const lastMsg = index > 0 ? messages[index - 1] : msg;
              // if (msg.delivered === true) {
              //   msgStatus = "delivered";
              // } else if (msg.read === true) {
              //   msgStatus = "read";
              // } else {
              //   msgStatus = "sent";
              // }

              if (msg.type === 'component') {
                if (msg.name === 'AstrologerRecommendation'
                  && (activeChatStatus === 'auto-end' || activeChatStatus === 'rejected')
                  && astrologerFullProfile.id === msg.astrologerID
                ) {
                  return <AstrologerRecommendation
                    key={msg.id}
                    type="regular-chat"
                    currentAstrologer={astrologerFullProfile}
                    APIParams={{ exclusion_astrologers: excludeAstrologers.join('|') }}
                    afterLoad={() => scrollToBottom()}
                    onNoButton={closeConversation}
                    onFailed={(msg, e) => {
                      setSnackBarState({
                        open: true,
                        message: msg || 'Something went wrong while connecting to the astrologer.',
                      })
                      console.warn(msg, e)
                    }}
                    startNewConsultation={(newAstrologer) => {
                      if (freeMessages?.messages) {
                        setFreeConsultationFlow({
                          astrologer: newAstrologer
                        })
                      }
                      setAstrologerInfo({ name: newAstrologer.fullName, profile_url: newAstrologer.profileUrl, id: newAstrologer.id })
                    }}
                    beforeNewConsultation={() => {
                      setReconnecting(true)
                      //setAstrologerInfo(false)
                      setAstrologerFullProfile(ast => { return { id: ast.id, name: ast?.name, profileUrl: ast?.profileUrl, perMinutePrice: ast?.perMinutePrice, salutation: ast?.salutation } })
                      setMessages(message => {
                        return message.filter((m, i) => i === message.length - 1)
                      })
                    }}
                    additionalData={{
                      freeChat: freeChatMinutes(astrologerFullProfile) > 0,
                      wallet_balance: parseInt(wallet_balance)
                    }}
                  />
                } else {
                  return (null)
                }
              }

              if ((msg.from === "bot" || msg.from.split("-")[0]) !== "user") {
                if (typeof msg.body.extra === 'undefined') {
                  msg.body.extra = {}
                }
                msg.body.extra.before = <InChatAvatar astrologer={freeConsultationFlow.astrologer || astrologerFullProfile} />
              }

              return (
                <React.Fragment key={msg.id}>
                  {/* {Moment(lastMsg.created).format("DD MMM yyy") !== Moment(msg.created).format("DD MMM yyy") && (
                  <div className="chat-day-break">{Moment(msg.created).format("MMMM D, yyyy")}</div>
                )} */}
                  {msg.from === "bot" ? (
                    ["user", "both"].indexOf(msg.body.text.visibility) >= 0 && (
                      <MetaMessage message={msg} msgStatus={msgStatus} MessageType={MessageType} astrolgerInfo={freeConsultationFlow.astrologer || astrologerFullProfile} />
                    )
                  ) : (
                    <>
                      {msg.from !== "breaker" ? (
                        <SingleMessage msg={msg} msgStatus={msgStatus} MessageType={MessageType} key={msg.id} />
                      ) : (
                        <div className="breaker">
                          <hr />
                        </div>
                      )}
                    </>
                  )}
                </React.Fragment>
              );
            })}
          {offlineMessages.map((msg, index) => {
            let msgStatus = "notSent";
            return (
              <SingleMessage msg={msg} astrolgerInfo={astrolgerInfo} msgStatus={msgStatus} MessageType={MessageType} />
            );
          })}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <>
        <div className={clsx("chat-window-footer", reconnecting ? 'reconnecting' : '')}>
          {activeChatStatus === "initiated" || activeChatStatus === "accepted" ? (
            <>
              <div className="chat-window-footer">


                <div className="chat-typing-status">
                  {isOtherUserTyping ? <span>Astrologer is typing...</span> : <></>}
                </div>

                {BrowserUtils.isNewUI() ?
                  <ChatQuestion
                    open={enableChatQuestionFeature && chatQuestion.open}
                    allQuestions={chatQuestion.questions}
                    excludeQuestions={chatQuestion.excludeQuestions}
                    onQuestion={async e => {
                      setChatQuestion(question => {
                        let excludeQuestions = question.excludeQuestions
                        excludeQuestions.push(e.question.id)
                        excludeQuestions = excludeQuestions.filter((value, index, self) => {
                          return self.indexOf(value) === index;
                        })
                        return { ...question, excludeQuestions, open: false }
                      })
                      setTextMessage(e.question.text)
                      setChatMessage(e.question.text)
                      messageInput.current.focus()
                      setChatQuestion(question => {
                        return { ...question, open: false }
                      })
                    }}
                    onClose={e => {
                      setChatQuestion(question => {
                        return { ...question, open: false }
                      })
                    }}
                  />
                  :
                  <></>
                }

                <form className="chat-form" onSubmit={handleSubmit}>
                  <textarea
                    className="chat-form-input-text"
                    onInput={handleMesssage}
                    value={chatMessage}
                    placeholder="Type something here"
                    ref={messageInput}
                    onKeyDown={handleKeyDown}
                    onFocus={e => {
                      setChatQuestion(question => {
                        return { ...question, open: chatMessage === '' }
                      })
                    }}
                    onClick={e => {
                      setChatQuestion(question => {
                        return { ...question, open: chatMessage === '' }
                      })
                    }}
                  ></textarea>
                  {/* <button className="chat-form-submit" type="submit" onClick={validateInput}>
                    <SendIcon />
                  </button> */}
                  <IconButton
                    className="chat-form-submit"
                    type="submit"
                    onClick={validateInput}
                    color="primary"
                  >
                    <SendIcon />
                  </IconButton>
                  {(BrowserUtils.isNewUI() && enableChatQuestionFeature) && (
                    <IconButton
                      className="chat-question-trigger-icon"
                      onClick={e => {
                        setChatQuestion(question => {
                          return { ...question, open: !question.open }
                        })
                      }}
                      color="primary"
                    >
                      {chatQuestion.open ?
                        <Close className="chat-question-close-trigger-icon" />
                        :
                        <QuestionIcon className="chat-question-open-trigger-icon" />
                      }
                    </IconButton>
                  )}
                </form>
              </div>
            </>
          ) : (
            <>
              <div className="chat-not-accepted">
                {(activeChatStatus === "failed" || activeChatStatus === "cancelled" || activeChatStatus === "rejected" || activeChatStatus === "completed") && showNewConsultationBox && (
                  <NewConsultationBox />
                )}
                {!BrowserUtils.isNewUI() && activeChatStatus === "pending" && showPendingConsultationBox ?
                  <PendingConsultationBox />
                  :
                  null
                }
              </div>
            </>
          )}
          {(BrowserUtils.isNewUI() && showAstrologerWaiting && astrologerFullProfile?.name) ?
            <AstrologerWaiting
              astrologer={astrologerFullProfile}
              freeConsultation={freeChatMinutes(astrologerFullProfile) > 0}
            />
            :
            null
          }

        </div>

      </>



      {showCallAstrologerPopup &&
        <AstrologerCallDialog
          className="callDialog"
          astrologer={astrologerFullProfile}
          handleClose={handleDlgClose}
          isStateOpen={showCallAstrologerPopupOpen}
          dialogType="suggest-call"
          clickFrom="chat"
          extras={{}}
          cashBackDiscount={() => { }}
          runUserUpdates={() => { }}
          callNowAction={(callNow) => {
            // console.log('callNowAction:activeChatStatus:', activeChatStatus)
            // Cancel the chat request
            if (activeChatStatus === 'pending') {
              cancelConsultation(callNow)
            } else {
              callNow()
            }

            // Trigger callNow from callback
            // console.log('Trigger callNow from callback', callNow)
          }}
        />
      }

      <AlertConfirm
        open={endChatAlert}
        handleClose={e => setEndChatAlert(false)}
        title="End chat"
        content="Are you sure you want to end your chat?"
        onConfirm={endConsultation}
        confirmText="End chat"
        confirmType="danger"
      />

      <AlertConfirm
        open={endRequestAlert}
        handleClose={e => setEndRequestAlert(false)}
        title="Cancel request"
        content="The astrologer will connect in less than 3 minutes. Are you sure you want to cancel your chat request?"
        onConfirm={cancelConsultation}
        confirmText="Cancel request"
        confirmType="danger"
        cancelText="Close"
      />

      {/* <>
        <div className="chat-window-footer">
          <div className="chat-not-accepted">
            <OfflineConsultationBox />
          </div>
        </div>
      </> */}
    </div>
  );
}

export default Chat;
